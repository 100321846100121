<template>
  <CRow>
    <CCol col="12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <transition name="slide">
        <CCard class="border-warning">
          <CCardHeader>
            <strong>Calendario de pagos</strong>
            <div class=" card-header-actions">
              <form class="form-inline">
                <b>Filas por p&aacute;gina</b>
                <select class="form-control mr-2 ml-1" @change="sizeChange($event)"
                        v-model="filasPorPagina">
                  <option v-for="option in sizeoptions" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>

                <button class="btn btn-outline-dark mr-2" type="button"
                        @click="borrarFiltroBtnOnClick()">
                  <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                  Quitar Filtro
                </button>

                <button class="btn btn-outline-primary ml-2" data-html="true"
                        data-toggle="tooltip" title="Nuevo pago por gestion" type="button"
                        @click="nuevo()">
                  <font-awesome-icon icon="fa-plus"/>
                  Nuevo
                </button>

                <button class="btn btn-outline-success ml-2" data-html="true"
                        data-toggle="tooltip"
                        title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                        type="button"
                        @click="excelBtnOnClick()">
                  <font-awesome-icon icon="fa-file-excel"/>
                  Exportar a Excel
                </button>
              </form>
            </div>
          </CCardHeader>
          <CCardBody class="p-0 pr-1">
            <JqxGrid :width="'100%'" ref="gridSystem"
                     :source="dataAdapter"
                     :autoheight="true" :autorowheight="false" :rowsheight="50"
                     :pageable="true"
                     :pagesize="parseInt(filasPorPagina)"
                     :virtualmode="true"
                     :rendergridrows="rendergridrows"
                     :columns="columns" :enablebrowserselection="true"
                     :enabletooltips="true" :filterable="true"
                     :showfilterrow="true"
                     :sortable="true"
                     :pagermode="'simple'" :localization="localization"
                     :theme="'bootstrap'"
                     @rowclick="onRowclick($event)"
                     :columnsresize="true"/>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>

    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="modalEditar"
        size=""
    >
      <div class="row mb-2">
        <div class="col text-right"><strong>Sede:</strong></div>
        <div class="col-9">
          <select class="form-control"
                  v-model="calendario.sede_id">
            <option v-for="option in sedeoptions" :value="option.value">
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col text-right"><strong>Gestión:</strong></div>
        <div class="col-9">
          <select class="form-control"
                  v-model="calendario.gestion">
            <template v-for="(option,key) in gestionoptions">
              <optgroup :label="key">
                <option v-for="suboption in option" :value="suboption.value">
                  {{ suboption.label }}
                </option>
              </optgroup>
            </template>
          </select>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col text-right"><strong>Pago:</strong></div>
        <div class="col-9">
          <select class="form-control"
                  v-model="calendario.pago_id">
            <option v-for="option in pagooptions" :value="option.id">
              {{ option.valor }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col text-right"><strong>Fecha de Pago:</strong></div>
        <div class="col-9">
          <input type="date" class="form-control"
                 v-model="calendario.fecha_pago"/>
        </div>
      </div>
      <div class="row">
        <div class="col text-right"><strong>Observaciones:</strong></div>
        <div class="col-9">
          <input type="text" class="form-control" v-model="calendario.observaciones">
        </div>
      </div>
      <template #header>
        <h6 class="modal-title">
          <span>Datos del pago</span>
        </h6>
        <CButtonClose @click="modalEditar = false"/>
      </template>
      <template #footer>
        <button
            type="button"
            class="btn btn-secondary"
            @click="modalEditar = false"
        >
          Regresar a la pantalla anterior
        </button>
        <button
            type="button"
            class="btn btn-primary"
            @click="store()"
        >
          Grabar
        </button>
      </template>
    </CModal>
  </CRow>
</template>

<script>
import axios from 'axios'
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";

var sourceLst;
var address;
var modalPdf;
var pagesizeoptions = [6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'calendariopagos',
  components: {
    Alerts,
    Toast,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      modalEditar: false,
      calendario: {
        sede_id: 0,
        gestion: '',
        pago_id: 0,
        fecha_pago: '',
        observaciones: ''
      },
      sedeoptions: [],
      gestionoptions: [],
      pagooptions: [],
      mesActual: '',
      items: [],
      toasts: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      sedeSeleccionada: localStorage.sedeSeleccionada,
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {text: '', datafield: "pago_id", hidden: true},
        {text: '', datafield: "sede_id", hidden: true},
        {text: '', datafield: "usuario_id", hidden: true},
        {
          text: 'Gestión', datafield: "gestion", width: '10%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Gesti&oacute;n</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar pago"});
          }
        },
        {
          text: 'Pago', datafield: "pago", width: '20%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Pago</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar pago"});
          }
        },
        {
          text: 'Observaciones', datafield: "observaciones", width: '25%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Observaciones</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar observaciones"});
          }
        },
        {
          text: 'Fecha de pago', datafield: "fecha_pago", width: '25%', cellsalign: 'center',
          cellsformat: 'yyyy-MM-dd',
          filterType: 'range',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Por Fecha de pago"});
          }
        },
        {
          text: '', width: '20%', cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<a class="btn btn-primary ml-1 mt-1 pl-1 pr-1" href="/#/inscripcion/' + rowdata.codigo_alumno + '/editar" title="Editar inscripcion de alumno nuevo">Editar</a>';
          }, filterable: false, sortable: false
        },
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/inscripcion/calendariopagos?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada;
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'id', type: "int"},
        {name: 'gestion', type: "string"},
        {name: 'pago_id', type: "int"},
        {name: 'sede_id', type: "int"},
        {name: 'pago', type: "string"},
        {name: 'fecha_pago', type: "date", format: 'yyyy-MM-dd'},
        {name: 'observaciones', type: "string"},
        {name: 'usuario', type: "string"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'id',
      sortcolumn: 'gestion',
      sortdirection: 'asc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  methods: {
    isarray(item) {
      return Array.isArray(item);
    },
    nuevo() {
      this.calendario.id = 0;
      this.calendario.sede_id = localStorage.sedeSeleccionada;
      this.calendario.gestion = this.mesActual;
      this.calendario.pago_id = this.pagooptions[0].valor;
      this.calendario.fecha_pago = '';
      this.calendario.observaciones = "";
      this.modalEditar = true;
    },
    onRowclick: function (event) {
      let datos = this.$refs.gridSystem.getrowdata(event.args.rowindex);
      this.calendario.id = datos.id;
      this.calendario.sede_id = datos.sede_id;
      this.calendario.gestion = datos.gestion;
      this.calendario.pago_id = datos.pago_id;
      let date = new Date(datos.fecha_pago);
      this.calendario.fecha_pago = date.getFullYear() + '-' +
          (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1) + '-' +
          (date.getDate() < 10 ? '0' : '') + date.getDate();
      this.calendario.observaciones = datos.observaciones;
      this.modalEditar = true;
    },
    store() {
      modalPdf.$refs.alert.show("Grabando datos");
      axios.post(this.$apiAdress + '/api/inscripcion/storecalendariopago?token=' + localStorage.getItem("api_token"),
          modalPdf.calendario
      )
          .then(function (response) {
            modalPdf.modalEditar = false;
            modalPdf.$refs.gridSystem.updatebounddata();
            modalPdf.$refs.mensajeToast.makeToast('Info', 'Registro creado satisfactoriamente.', 'success');
            modalPdf.$refs.alert.hide();
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    excelBtnOnClick: function () {
      this.$refs.gridSystem.hidecolumn('documentos');
      this.$refs.gridSystem.hidecolumn('editar');
      this.$refs.gridSystem.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'Inscritos' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));

      this.$refs.gridSystem.showcolumn('documentos');
      this.$refs.gridSystem.showcolumn('editar');
      this.$refs.gridSystem.showcolumn('eliminar');
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {}
    }
  },
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      modalPdf.sede = data;
    });
    axios.get(this.$apiAdress + '/api/inscripcion/opcionescalendariopagos?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          modalPdf.sedeoptions = response.data.sedeoptions;
          modalPdf.gestionoptions = response.data.gestionoptions;
          modalPdf.pagooptions = response.data.pagooptions;
          modalPdf.mesActual = response.data.mesActual;
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
  },
}
</script>